import Home from './website-views/Home.vue'
import Faqs from './website-views/Faqs.vue'
import SuccessStories from './website-views/SuccessStories.vue'
import ContactUs from './website-views/Contacts.vue'
import TrainingForm from './website-views/forms/TrainingForm.vue'
import VolunteerForm from './website-views/forms/VolunteerForm.vue'
import TeachingForm from './website-views/forms/TeachingForm.vue'
import JobApplicationForm from './website-views/forms/JobApplicationForm.vue'
import FullDetails from './website-views/FullDetails'
import ComingSoon from './website-views/ComingSoon'
import Error from '../shared/views/Error'
import MasterLayout from '../../layouts/MasterLayout'
import BasicLayout from '../../layouts/BasicLayout'
import News from "./website-views/news/News";
import Events from "./website-views/news/Event";
import UpcomingEvents from "./website-views/news/UpcomingEvent";
import NewsView from "./website-views/News";
import ZadiComingSoon from "./website-views/zadi/ZadiComingSoon";
import ZadiFinish from "./website-views/zadi/ZadiFinish"
import OrphanSponsorshipSteps from "./website-views/orphanSponsorshipSteps"


const websiteRoutes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {layout: MasterLayout}
    },
    {
        path: '/kareem',
        name: 'Kareem',
        component: () => import('./website-views/Kareem'),
        meta: {layout: MasterLayout}
    },
    {
        path: '/events',
        name: 'Event', 
        component: () => import('./website-views/events/Event.vue'),
    },
    {
        path: '/events/OTP',
        name: 'SendOTP', 
        component: () => import('./website-views/events/SendOTP.vue'),
    },
    {
        path: '/tamayoz2024',
        name: 'TenYears',
        component: () => import('./website-views/TenYears.vue'),
        meta: { layout: MasterLayout }
    },
    {
        path: '/zadi',
        name: 'ZadiHome',
        component: () => import('./website-views/zadi-platform/Home.vue'),
        meta: { layout: MasterLayout },
    },
    {
        path: '/zadi/magazines',
        name: 'ZadiMagazines',
        component: () => import('./website-views/zadi-platform/Magazines.vue'),
        meta: { layout: MasterLayout },
    },
    {
        path: '/zadi/books',
        name: 'ZadiBooks',
        component: () => import('./website-views/zadi-platform/Books.vue'),
        meta: { layout: MasterLayout },
    },
    {
        path: '/zadi/books/:id',
        name: 'ZadiBookDetails',
        component: () => import('./website-views/zadi-platform/BookDetails.vue'),
        meta: { layout: MasterLayout },
    },
    {
        path: '/news',
        name: 'News',
        component: NewsView,
        meta: {layout: MasterLayout},
        children: [
          {
            path: 'latestNews',
            name: 'NewsNews',
            component: News,
            meta: {layout: MasterLayout},
          },
          {
            path: 'event',
            name: 'NewsEvent',
            component: Events,
            meta: {layout: MasterLayout},
          },
          {
            path: 'upcoming',
            name: 'NewsUpcomingEvent',
            component: UpcomingEvents,
            meta: {layout: MasterLayout},
          }
        ]
    },
    {
        path: '/faqs',
        name: 'Faqs',
        component: Faqs,
        meta: {layout: MasterLayout}
    },
    {
        path: '/success-stories',
        name: 'SuccessStories',
        component: SuccessStories,
        meta: {layout: MasterLayout}
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs,
        meta: {layout: MasterLayout}
    },
    {
        path: '/training-form',
        name: 'TrainingForm',
        component: TrainingForm,
        meta: {layout: MasterLayout}
    },
    {
        path: '/volunteer-form',
        name: 'VolunteerForm',
        component: VolunteerForm,
        meta: {layout: MasterLayout}
    },
    {
        path: '/teaching-form',
        name: 'TeachingForm',
        component: TeachingForm,
        meta: {layout: MasterLayout}
    },
    {
        path: '/job-application-form',
        name: 'JobApplicationForm',
        component: JobApplicationForm,
        meta: {layout: MasterLayout}
    },
    {
        path: '/book',
        component: ZadiComingSoon,
        meta: {layout: BasicLayout}
    },
    {
        path: '/book/register-form',
        component: ZadiFinish,
        meta: {layout: MasterLayout}
    },
    {
        path: '/full-details',
        name: 'Full-Details',
        component: FullDetails,
        meta: {layout: MasterLayout}
    },
    {
        path: '/coming-soon',
        name: 'coming-soon',
        component: ComingSoon,
        meta: {layout: BasicLayout}
    },
    // {
    //     path: '/',
    //     name: 'coming-soon',
    //     component: ComingSoon,
    //     meta: {layout: BasicLayout}
    // },
    {
        path: '/error/204',
        name: 'Error',
        component: Error
    },
    {
        path: '*',
        name: 'catchAll',
        component: Home
    },
  {
    path: '/orphanSponsorshipSteps',
    component: OrphanSponsorshipSteps,
    name: 'orphanSponsorshipSteps',
    meta: {layout:  MasterLayout}
  },
];

export default websiteRoutes
