<template>
    <div class="home">
        <WebsiteBanner></WebsiteBanner>
        <!-- /#theme-banner-one -->
        <!--
          =============================================
              What WE Do
          ==============================================
        -->

        <website-ten-years/>
        
        <WebsiteAbout></WebsiteAbout>
        <!-- /.agn-what-we-do -->
        <!--
             =============================================
               Services
             ==============================================
         -->
        <WebsiteServices></WebsiteServices>
        <!--
              =============================================
                  Video
              ==============================================
         -->
        <WebsiteVideo></WebsiteVideo>
        <!--
              =====================================================
                  Testimonial
              =====================================================
        -->
        <WebsiteTestimonial v-bind:testimonials="testimonials" v-if="isDataFetched"></WebsiteTestimonial>
        <!--
            =============================================
              Theme Counter
            ==============================================
        -->
        <WebsiteStatistics v-bind:analytics="analytics" v-if="isDataFetched"></WebsiteStatistics>
        <!--
              =============================================
                  Contact Us
              ==============================================
         -->
        <WebsiteContactBanner></WebsiteContactBanner>
    </div>
</template>

<script>
    // @ is an alias to /src
    import WebsiteStatistics from '@/app/website/website-components/website-homepage/website-statistics'
    import WebsiteBanner from '@/app/website/website-components/website-homepage/website-banner'
    import WebsiteAbout from '@/app/website/website-components/website-homepage/website-about'
    import WebsiteContactBanner from '@/app/website/website-components/website-homepage/website-contact-banner'
    import WebsiteTestimonial from '@/app/website/website-components/website-homepage/website-testimonial'
    import WebsiteVideo from '@/app/website/website-components/website-homepage/website-video'
    import WebsiteServices from '@/app/website/website-components/website-homepage/website-services'
    import WebsiteTenYears from '@/app/website/website-components/website-homepage/website-ten-years'

    import {RepositoryFactory} from "../repositories/RepositoryFactory";

    const homePageRepository = RepositoryFactory.get('homePage')

    export default {
        name: 'Home',
        metaInfo: {
            title: 'الرئيسية',
        },
        components: {
            WebsiteStatistics,
            WebsiteBanner,
            WebsiteAbout,
            WebsiteContactBanner,
            WebsiteTestimonial,
            WebsiteVideo,
            WebsiteServices,
            WebsiteTenYears
        },
        data() {
            return {
                testimonials: [],
                analytics: [],
                isDataFetched: false
            }
        },
        created() {
            homePageRepository.fetchHomePage().then((data) => {
                this.analytics = data['analytics'];
                this.testimonials = data['testimonials'];
                this.isDataFetched = true;
            });
        },
    }
</script>
